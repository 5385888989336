// 9fbef606107a605d69c0edbcd8029e5d

export const DEFAULT_LOCALE = "en-GB";

export const RTL_LOCALES = [
  "ar-AE",
  "ar-BH",
  "ar-EG",
  "ar-IQ",
  "ar-JO",
  "ar-KW",
  "ar-LB",
  "ar-OM",
  "ar-QA",
  "ar-SA",
  "ar-SD",
  "ar-SS",
  "ar-SY",
  "ar-YE",
  "he-IL",
];

export const DATA_CONTAINER_ELEMENT = "dhl-cj-personalization-container";

export const DEFAULT_FREQUENCY = "DAILY";

export const REGIONS = {
  DOMESTIC: "DOMESTIC",
  REGIONAL: "REGIONAL",
  GLOBAL: "GLOBAL",
};

export const PRODUCTS = {
  PARCEL: "PARCEL",
  FREIGHT: "FREIGHT",
  MAIL: "MAIL",
};

export const PRODUCTS_ORDER = ["PARCEL", "FREIGHT", "MAIL"];
export const REGIONS_ORDER = ["DOMESTIC", "REGIONAL", "GLOBAL"];

export const DEV_MODES = {
  BU_SETTINGS: "bu-settings",
  COPY: "copy",
  SETTINGS: "settings",
  SLOTS: "slots",
  RTL: "rtl",
};

export const CONTENT_DIRECTIONS = {
  LTR: "ltr",
  RTL: "rtl",
};

export const STEPS = {
  CONNECTORS: "connectors",
  CONTACT_FORM: "contact-form",
  INTRO: "intro",
  PRODUCT: "select-product",
  PRODUCT_DETAILS: "select-scale",
  SERVICES: "services",
  THANK_YOU: "thank-you",
};

export const PROFILES = {
  FS: "FS",
  FSX: "FSX",
};

export const INTEGRATIONS = {
  DEVELOPMENT: "DEVELOPMENT",
  DHLFORBUSINESS: "DHLFORBUSINESS",
  DISCOVER: "DISCOVER",
  EXPRESSMOBILEAPP: "EXPRESSMOBILEAPP",
  GOGREENPLUS: "GOGREENPLUS",
  MYDHLPLUS: "MYDHLPLUS",
  SMEHUB: "SMEHUB",
  SMEHUB2: "SMEHUB2",
  SMEHUB3: "SMEHUB3",
  SMEPARTNERSHIP: "SMEPARTNERSHIP",
  TAS: "TAS",
};

export const THEMES = {
  MYDHLPLUS: "MYDHLPLUS",
};

export const QUALIFIER = {
  FSX: "FSX",
  QUICK_ECS: "QUICK_ECS",
};

export const CONTENT_PREFIXES = {
  FS: "FS2",
  FSX: "FSX",
};

export const DEFAULT_PROFILE = PROFILES.FS;
export const DEFAULT_AB_TESTING = null;
export const DEFAULT_CONTENT_PREFIX = CONTENT_PREFIXES[DEFAULT_PROFILE];

export const PROFILE_SPECIFIC_BUS = {
  FSX: "EXP",
};

export const PRESELECTED_PRODUCTS = {
  FSX: [PRODUCTS.PARCEL],
};

export const DEFAULT_NUMBER_OF_STEPS = {
  FS: 4,
  FSX: 1,
};

export const DEFAULT_FIRST_STEP = {
  FS: STEPS.PRODUCT,
  FSX: STEPS.CONTACT_FORM,
};

export const STEP_NUMBERS = {
  FS: {
    [STEPS.PRODUCT]: 1,
    [STEPS.PRODUCT_DETAILS]: 2,
    [STEPS.SERVICES]: 3,
    [STEPS.CONNECTORS]: 4,
    [STEPS.CONTACT_FORM]: 4,
    [STEPS.THANK_YOU]: 4,
  },
  FSX: {
    [STEPS.PRODUCT_DETAILS]: 1,
    [STEPS.CONNECTORS]: 2,
    [STEPS.CONTACT_FORM]: 2,
    [STEPS.THANK_YOU]: 2,
  },
};

export const DEFAULT_STEP_NUMBER = 1;

export const STEP_FLOW = {
  FS: [
    STEPS.PRODUCT,
    STEPS.PRODUCT_DETAILS,
    STEPS.SERVICES,
    STEPS.CONTACT_FORM,
  ],
  FSX: [STEPS.PRODUCT_DETAILS, STEPS.CONTACT_FORM],
};

export const STEPS_WITHOUT_SCREEN_READER_ANNOUNCEMENT = [STEPS.INTRO];

export const STEPS_WITHOUT_STEP_INDICATOR = [STEPS.INTRO, STEPS.THANK_YOU];

export const PAGE_VIEW_TITLES = {
  CONNECTORS: "Connectors",
  CONTACT_FORM: "Contact Form",
  PRODUCT_DETAILS: "Product Details",
  SERVICES: "Services",
  THANK_YOU: "Thank You",
};

export const SUCCESS_MODAL_VARIATIONS = {
  DEFAULT: "default",
  SCHEDULE: "scheduled",
};

export const MODALS = {
  CONNECTOR_SELECTOR: "connector-selector",
  ERROR: "error",
  ERROR_CALLBACK_REMOTE: "error-callback-remote",
  NO_BUSINESS_UNITS: "no-business-units",
  SUCCESS: "success",
};

export const MODALS_FOR_TRACKING = [
  MODALS.ERROR,
  MODALS.NO_BUSINESS_UNITS,
  MODALS.SUCCESS,
];

export const FREQUENCY_OPTIONS = ["Daily", "Weekly", "Monthly"].map(
  (label) => ({
    key: label.toUpperCase(),
    label,
    value: label.toUpperCase(),
  })
);

export const PARCEL_OPTIONS = {
  EXPEDITED: "expedited",
  STANDARD: "standard",
};

export const SCHEDULER_OPTION_DAYS = 5;
export const SCHEDULER_SLOT_BUFFER_MINUTES = 30;

export const GLOBAL_OBJECT = "__GAQ_FS_GLOBAL__";

export const LOCALES_FONT_ASSISTANT = ["he"];
export const LOCALES_FONT_VIETNAMESE = ["vi"];
export const LOCALES_FONT_ARABIC = ["ar"];

export const STYLES_BASE_FONT_SIZE = 10;
export const STYLES_CUSTOM_BREAKPOINT_CARDS = "375px";
export const STYLES_VIEWPORTS = {
  SMALL: "320px",
  MEDIUM: "768px",
  LARGE: "1024px",
  XLARGE: "1365px",
  XXLARGE: "1920px",
  XXXLARGE: "2560px",
};

export const VALIDATE_PHONE_NUMBER_LIB = "isValidPhoneNumber";

export const BU_NAMES = {
  DBD: "Blue Dart DHL",
  DGF: "DHL Global Forwarding",
  DPI: "Deutsche Post International",
  DSC: "DHL Supply Chain",
  DST: "DHL SmarTrucking", // not in active use
  ECO: "DHL eCommerce",
  EXP: "DHL Express",
  FRE: "DHL Freight",
  PAR: "DHL eCommerce", // formerly DHL Parcel
  PNP: "DHL Paket",
  UKM: "DHL eCommerce", // formerly DHL Parcel UK
};

export const REROUTING_ALTERNATIVE_SERVICES = {
  GAQ: "Get A Quote",
  EXP: "DHL Express",
  EXE: "Express Easy",
  GFQ: "DGF General Quote",
  GFI: "DGF General Inquiry",
};

export const MAIN_FORM_ID = "lead-wizard-form";

export const COUNTRY_KEY_NAMESPACE = "CountryAutocompleteField";

export const A11Y_CURRENT_STEP_ELEMENT = "a11y-current-step";

export const DEFAULT_FEATURE_TOGGLES = {
  enhancedBUCard: null,
  hideThankYouTeaser: false,
  leftAlignContent: false,
  medalliaSurveyEnabled: false,
  openLinksInSameTab: false,
  scrollOffset: null,
  showBUTransparencyInformation: false,
  showConnectors: {},
  showGAQRampOffForNoBUs: false,
  showIntroPage: false,
  showProductDetailsPage: false,
  showRerouting: {},
  withPhoneExtension: false,
};

export const CONTACT_FORM_FIELD_PATTERNS = {
  address: "^.{0,100}$",
  city: "^.{0,40}$",
  company: "^.{0,40}$",
  /**
   * Yes we are aware this is not a perfect email validation pattern and it is in general
   * a not-perfect concept to pre-validate email addresses, but here in this case we are using the
   * same pattern as our backend systems to avoid entries getting rejected further down the process
   */
  email: "^[\\w+-\\.]+@([\\w-]+\\.)+[\\w-]{2,}$",
  firstName: "^.{0,40}$",
  lastName: "^.{0,50}$",
  phoneExtension: "^[0-9]{0,6}$",
  postalCode: "^.{0,12}$",
};

export const API_REQUEST_HEADERS = {
  APP_CODE: "App-Code",
  CONTENT_TYPE: "Content-Type",
  X_B3_SPAN_ID: "X-B3-SpanId",
  X_B3_TRACE_ID: "X-B3-TraceId",
  TRACEPARENT: "traceparent",
  X_CORRELATION_ID: "X-Correlation-ID",
};

export const API_REQUEST_HEADER_VALUES = {
  APP_CODE: "DHL.com",
};

export const PAYLOAD_CONTACT_FORM_TYPES = {
  CALLBACK: "CALLBACK",
  CALLBACK_REMOTE: "CALLBACK2",
  DEFAULT: "DEFAULT",
  VANILLA: "VANILLA",
};

export const LEAD_HTTP_STATUS_CODES = {
  ERROR: "409",
};

export const CALLBACK_REMOTE_SLOTS_HTTP_STATUS_CODES = {
  NO_CONTENT: "204",
  SUCCESS: "200",
};

export const LEAD_CALLBACK_REMOTE_ERROR_CODES = {
  SLOT_INVALID: "10002",
  SLOT_TAKEN: "10001",
};

export const LEAD_CALLBACK_REMOTE_SUBMIT_ERRORS = {
  SLOT_INVALID: "SLOT_INVALID",
  SLOT_TAKEN: "SLOT_TAKEN",
};

export const DEFAULT_LEAD_META_VALUES = {
  leadSourceCallback: "FS - Callback",
  leadSourceDefault: "FS",
  sourceType: "DHL.com",
};

export const CONNECTORS_DEFAULT_WEEKEND_DAYS = ["Sat", "Sun"];

export const WEEK_DAYS = {
  0: "Sun",
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
};

export const UTF_CONVERSION_NAMES = {
  REQUEST_BUSINESS_ACCOUNT: "FS Request Business Account",
};

export const UTF_CONVERSION_TYPES = {
  SUBMISSION: "Submission",
};

export const UTF_EVENT_CONTEXTS = {
  CALLBACK_REMOTE: "Callback Remote",
  DEFAULT: "Default",
  SCHEDULED: "Scheduled",
};

export const UTF_EVENT_INTERACTIONS = {
  CLICK: "Click",
  INPUT: "Input",
  SUBMIT: "Submit",
};

export const UTF_EVENT_NAMES = {
  CALL_IN: "Call In",
  CALLBACK_REMOTE: "Callback Remote",
  CONTACT_FORM: "Contact Form",
  CONVERSION: "Conversion",
  FORM_INPUT: "Form Input",
  FORM_SUBMIT: "Form Submit",
  LIVE_CHAT: "Live Chat",
  NEXT: "Next",
  NEXT_SERVICES_UPPER_CTA: "Next Services Upper Cta",
  OPEN_AN_ACCOUNT: "Open An Account",
  PREVIOUS: "Previous",
  PRODUCT_CARD_ACTIVATION: "Product Card Activation",
  PRODUCT_CARD_DEACTIVATION: "Product Card Deactivation",
  REGION_NOTICE: "Region Notice",
  SCHEDULE_A_CALLBACK: "Schedule A Callback",
  SELF_REGISTER: "Self Register",
  SELF_ONBOARDING: "Self Onboarding",
  SINGLE_PRODUCT: "Single Product",
  SINGLE_REGION: "Single Region",
  SLOT_INVALID: "Slot Invalid",
  SLOT_TAKEN: "Slot Taken",
  TEASER: "Teaser",
  THRESHOLD_NOTICE: "Threshold Notice",
  UNSAVED_CHANGES_DIALOG: "Unsaved Changes Dialog",
};

export const UTF_EVENT_POSITIONS = {
  CALLBACK_REMOTE: "Callback Remote",
  CONNECTORS: "Connectors",
  SERVICES: "Services",
  THANK_YOU: "Thank You",
  PRODUCT: "Product",
  PRODUCT_DETAILS: "Product Details",
  REROUTING_DISPLAY: "Rerouting Display",
  REROUTING_CLICK: "Rerouting Click",
};

export const UTF_EVENT_TYPES = {
  BUTTON: "Button",
  FORM: "Form",
  INPUT_FIELD: "Input Field",
};

export const DEFAULT_TIME_ZONE = "Europe/London";

export const DEFAULT_CONNECTORS_ORDER = [
  "callBack",
  "callBackRemote",
  "contactForm",
  "liveChat",
  "selfRegister",
  "openAnAccount",
  "callIn",
];

export const FILLABLE_CONTACT_FORM_FIELDS = [
  "firstName",
  "lastName",
  "address",
  "city",
  "company",
  "email",
  "comments",
  "phoneNumber",
  "zip",
];

export const DHL_DOMAINS = {
  LOWER_ENV_DOMAIN: "dhl.dhl",
  PROD_DOMAIN: "www.dhl.com",
  DHL_BASE_URL: "https://www.dhl.com",
};

export const AB_TESTING_CAMPAIGNS = {
  VARIANT: "variant",
  CONTROL: "control",
  CTA_COPY: "cta-copy",
  SERVICES_CTA: "services-cta",
  REROUTING_THRESHOLD_COPY: "rerouting-threshold-copy",
};

export const COUNTRY_CODES = [
  "AF",
  "AL",
  "DZ",
  "AS",
  "AD",
  "AO",
  "AI",
  "AG",
  "AR",
  "AM",
  "AW",
  "AU",
  "AT",
  "AZ",
  "BS",
  "BH",
  "BD",
  "BB",
  "BY",
  "BE",
  "BZ",
  "BJ",
  "BM",
  "BT",
  "BO",
  "XB",
  "BA",
  "BW",
  "BV",
  "BR",
  "VG",
  "BN",
  "BG",
  "BF",
  "BI",
  "KH",
  "CM",
  "CA",
  "IC",
  "CV",
  "KY",
  "CF",
  "TD",
  "CL",
  "CN",
  "CX",
  "CC",
  "CO",
  "KM",
  "CK",
  "CR",
  "HR",
  "CU",
  "XC",
  "CY",
  "CZ",
  "CI",
  "CD",
  "DK",
  "DJ",
  "DM",
  "DO",
  "EC",
  "EG",
  "SV",
  "GQ",
  "ER",
  "EE",
  "ET",
  "FK",
  "FO",
  "FJ",
  "FI",
  "FR",
  "GF",
  "GA",
  "GM",
  "GE",
  "DE",
  "GH",
  "GI",
  "GR",
  "GL",
  "GD",
  "GP",
  "GU",
  "GT",
  "GG",
  "GN",
  "GW",
  "GY",
  "HT",
  "HN",
  "HK",
  "HU",
  "IS",
  "IN",
  "ID",
  "IR",
  "IQ",
  "IE",
  "IM",
  "IL",
  "IT",
  "JM",
  "JP",
  "JE",
  "JO",
  "KZ",
  "KE",
  "KI",
  "KV",
  "KW",
  "KG",
  "LA",
  "LV",
  "LB",
  "LS",
  "LR",
  "LY",
  "LI",
  "LT",
  "LU",
  "MO",
  "MK",
  "MG",
  "MW",
  "MY",
  "MV",
  "ML",
  "MT",
  "MH",
  "MQ",
  "MR",
  "MU",
  "YT",
  "MX",
  "FM",
  "MD",
  "MC",
  "MN",
  "ME",
  "MS",
  "MA",
  "MZ",
  "MM",
  "NA",
  "NR",
  "NP",
  "NL",
  "XN",
  "NC",
  "NZ",
  "NI",
  "NE",
  "NG",
  "NU",
  "NF",
  "KP",
  "MP",
  "NO",
  "OM",
  "PK",
  "PW",
  "PA",
  "PG",
  "PY",
  "PE",
  "PH",
  "PN",
  "PL",
  "PT",
  "PR",
  "QA",
  "XS",
  "CG",
  "RE",
  "RO",
  "RU",
  "RW",
  "XE",
  "SH",
  "KN",
  "LC",
  "XM",
  "PM",
  "VC",
  "WS",
  "SM",
  "ST",
  "SA",
  "SN",
  "RS",
  "SC",
  "SL",
  "SG",
  "SK",
  "SI",
  "SB",
  "SO",
  "ZA",
  "KR",
  "SS",
  "ES",
  "LK",
  "XY",
  "SD",
  "SR",
  "SJ",
  "SZ",
  "SE",
  "CH",
  "SY",
  "PF",
  "TW",
  "TJ",
  "TZ",
  "TH",
  "TL",
  "TG",
  "TK",
  "TO",
  "TT",
  "TN",
  "TR",
  "TM",
  "TC",
  "TV",
  "VI",
  "UG",
  "UA",
  "AE",
  "GB",
  "US",
  "UY",
  "UZ",
  "VU",
  "VA",
  "VE",
  "VN",
  "WF",
  "YE",
  "ZM",
  "ZW",
];
