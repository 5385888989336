import React, { memo } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { DhlButton } from "@dhl-official/react-library";
import { ChevronBack as IconChevronBack } from "@dhl-official/icons";
import { FormattedMessage } from "react-intl";
import ButtonLoader from "../../ButtonLoader";
import {
  AB_TESTING_CAMPAIGNS,
  PROFILES,
  STEPS,
  STYLES_VIEWPORTS,
  THEMES,
  UTF_EVENT_TYPES,
  UTF_EVENT_INTERACTIONS,
  UTF_EVENT_NAMES,
} from "../../../utils/constants";
import { trackContentInteraction } from "../../../utils/track-utf";

// #region Component Styles
const Container = styled.div`
  align-items: center;
  background-color: var(--fs-form-container-background-color);
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: calc(var(--dui-size-space-7x) * 2);
  width: 100%;

  @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
    flex-direction: row-reverse;
  }
`;

const getStyleForDisabledButton = ($visuallyDisabled) => {
  return (
    $visuallyDisabled &&
    css`
      & > .styled.primary:is(button) {
        background-color: rgba(0, 0, 0, 0.2);
        box-shadow: unset;
        color: var(--dui-color-white-500);
      }
    `
  );
};

const NextButton = styled(DhlButton)`
  & button {
    cursor: pointer;
    margin: var(--dui-size-space-3x);
    min-width: 70%;
    width: auto;
    @media screen and (min-width: ${STYLES_VIEWPORTS.MEDIUM}) {
      min-width: 210px;
    }
  }

  ${({ $visuallyDisabled }) => getStyleForDisabledButton($visuallyDisabled)};
`;

const PreviousButton = styled.button.attrs({
  type: "button",
})`
  color: var(--dui-color-black-400);
  cursor: pointer;
  align-items: center;
  background-color: transparent;
  border: solid 2px transparent;
  display: flex;
  font-family: var(--dui-font-family);
  font-size: var(--dui-size-font-md);
  padding: 10px 30px 11px 30px;
  transition: border-color var(--dui-duration-default);

  &:focus-visible {
    border-color: var(--dui-color-black-400);
  }
`;

const getMyDHLPlusThemeStyles = () => css`
  color: var(--mydhlplus-color-blue-400);
`;

const BackArrow = styled(IconChevronBack)`
  color: var(--dui-color-red-500);
  height: 20px;
  width: 20px;
  margin-right: 5px;
  transform: rotate(
    ${({ $isRtlLayout }) => ($isRtlLayout ? "180deg" : "0deg")}
  );

  ${({ $theme }) => $theme === THEMES.MYDHLPLUS && getMyDHLPlusThemeStyles()};
`;
// #endregion

const NEXT_BUTTON_CONTENT_IDS = {
  [PROFILES.FS]: {
    [STEPS.PRODUCT]: "buttonProceed",
    [STEPS.PRODUCT_DETAILS]: "buttonProceed",
    [STEPS.SERVICES]: "buttonContinueToContact",
    [STEPS.CONTACT_FORM]: "buttonSend",
  },
  [PROFILES.FSX]: {
    [STEPS.INTRO]: "buttonProceedFromIntro",
    [STEPS.PRODUCT_DETAILS]: "buttonContinueToContact",
    [STEPS.CONTACT_FORM]: "buttonSend",
  },
};

const getNextButtonContentId = (activeStep, contentPrefix, profile) => {
  return `${contentPrefix}.MultiPageFormStep.${
    NEXT_BUTTON_CONTENT_IDS?.[profile]?.[activeStep] || "buttonProceed"
  }`;
};

const getNextButtonDataTestid = (id, step, shouldShowConnectorsStep) => {
  if (step === 3 && shouldShowConnectorsStep) {
    return "btn-show-connectors";
  }
  return `${id}-button-proceed-${step}`;
};

const getNextButtonDataTracking = (
  connectorsBU,
  id,
  step,
  shouldShowConnectorsStep
) => {
  if (step === 3 && shouldShowConnectorsStep) {
    return `regularShipmentLP.interactions.connectors.${connectorsBU}.showOptionsButtonClicked`;
  }
  return `${id}-button-proceed`;
};

const triggerClickTrackingEvent = (profile, name, activeStep) => {
  trackContentInteraction({
    context: profile,
    name,
    type: UTF_EVENT_TYPES.BUTTON,
    interaction: UTF_EVENT_INTERACTIONS.CLICK,
    position: activeStep,
  });
};

const getAbTestContent = (abTesting, activeStep) => {
  if (abTesting === AB_TESTING_CAMPAIGNS.CTA_COPY) {
    if (activeStep === STEPS.PRODUCT_DETAILS) {
      return "See Service Match";
    }
    if (activeStep === STEPS.SERVICES) {
      return "Get a Custom Offer";
    }
  }
  return null;
};

const StepNavigator = ({
  abTesting,
  activeStep,
  contentPrefix,
  connectorsBU,
  id,
  isNextStepEnabled,
  isRtlLayout,
  isWaitingForEndpoint,
  onPrevious,
  profile,
  shouldHidePreviousStep,
  shouldHideProceedStep,
  shouldShowConnectorsStep,
  step,
  theme,
}) => {
  const abTestContent = getAbTestContent(abTesting, activeStep);
  return (
    <Container>
      {!shouldHideProceedStep && (
        <NextButton
          $visuallyDisabled={!isNextStepEnabled}
          isDisabled={isWaitingForEndpoint ? true : null}
          dataAriaDisabled={!isNextStepEnabled}
          dataTestid={getNextButtonDataTestid(
            id,
            step,
            shouldShowConnectorsStep
          )}
          dataTracking={getNextButtonDataTracking(
            connectorsBU,
            id,
            step,
            shouldShowConnectorsStep
          )}
          name={`${id}-button-proceed`}
          type="submit"
          clickEvent={() =>
            triggerClickTrackingEvent(profile, UTF_EVENT_NAMES.NEXT, activeStep)
          }
        >
          <div>
            {!isWaitingForEndpoint ? (
              abTestContent || (
                <FormattedMessage
                  id={getNextButtonContentId(
                    activeStep,
                    contentPrefix,
                    profile
                  )}
                />
              )
            ) : (
              <ButtonLoader />
            )}
          </div>
        </NextButton>
      )}
      {!shouldHidePreviousStep && (
        <PreviousButton
          data-testid={`${id}-button-previous-${step}`}
          data-tracking={`${id}-button-previous`}
          name={`${id}-button-previous`}
          onClick={() => {
            onPrevious();
            triggerClickTrackingEvent(
              profile,
              UTF_EVENT_NAMES.PREVIOUS,
              activeStep
            );
          }}
        >
          <BackArrow $isRtlLayout={isRtlLayout} $theme={theme} />
          <FormattedMessage id="FS2.MultiPageFormStep.buttonPrevious" />
        </PreviousButton>
      )}
    </Container>
  );
};

StepNavigator.propTypes = {
  abTesting: PropTypes.string,
  activeStep: PropTypes.oneOf(Object.values(STEPS)).isRequired,
  connectorsBU: PropTypes.string,
  contentPrefix: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isNextStepEnabled: PropTypes.bool,
  isRtlLayout: PropTypes.bool.isRequired,
  isWaitingForEndpoint: PropTypes.bool,
  onPrevious: PropTypes.func.isRequired,
  profile: PropTypes.oneOf(Object.values(PROFILES)).isRequired,
  shouldHidePreviousStep: PropTypes.bool,
  shouldHideProceedStep: PropTypes.bool,
  shouldShowConnectorsStep: PropTypes.bool,
  step: PropTypes.number.isRequired,
  theme: PropTypes.oneOf(Object.values(THEMES)),
};

StepNavigator.defaultProps = {
  isNextStepEnabled: false,
  shouldHidePreviousStep: false,
  shouldHideProceedStep: false,
};

export default memo(StepNavigator);
