import { css } from "styled-components";

export const getMyDHLPlusThemeStyles = () => css`
  --mydhlplus-color-green-400: #1f891f;
  --mydhlplus-color-green-500: #28a928;
  --mydhlplus-color-grey-400: #444444;
  --mydhlplus-color-blue-400: #007acc;
  --mydhlplus-color-blue-500: rgba(0, 122, 204, 0.95);

  --mydhlplus-font-color: var(--mydhlplus-color-grey-400);
  --mydhlplus-copy-font-size: var(--dui-size-font-sm);
  --mydhlplus-copy-line-height: 1.375;

  --dui-font-family: "Arial";
  --dui-size-font-md: var(--dui-size-font-sm);
  --fs-application-container-padding-top: 0;
  --fs-application-connector-margin-bottom: var(--dui-size-space-3x);
  --fs-form-container-background-color: transparent;
  --fs-connectors-icon-color: var(--dui-color-gray-500);
  --fs-button-background-color: var(--mydhlplus-color-green-500);
  --fs-button-background-color-secondary: var(--mydhlplus-color-green-400);
  --fs-successbox-background: none;

  dhl-card {
    --dui-box-shadow-sm: 0px 0px 1px;
    --dui-box-shadow-lg: 0px 0px 1px;
  }

  dhl-button {
    --dui-color-red-400: var(--mydhlplus-color-green-400);
    --dui-color-red-500: var(--mydhlplus-color-green-500);
    --dui-color-red-600: var(--mydhlplus-color-green-400);
  }

  dhl-accordion dhl-panel dhl-button {
    --dui-color-red-400: var(--mydhlplus-color-blue-500);
    --dui-color-red-500: var(--mydhlplus-color-blue-400);
  }
`;
export const getAssistantFontStyles = () => css`
  --dui-font-family: Assistant, Delivery, Verdana, sans-serif;
`;

export const getArabicFontStyles = () => css`
  --dui-font-family: "Delivery Arabic", Delivery, Verdana, sans-serif;
`;

export const getVietnameseFontStyles = () => css`
  --dui-font-family: "Delivery Vietnamese", Delivery, Verdana, sans-serif;
`;
