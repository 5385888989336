import React from "react";
import PropTypes from "prop-types";
import { DhlDropdown } from "@dhl-official/react-library";
import FF from "../../../utils/functional/form-field";

const CountryCallingCodeField = ({
  field,
  label,
  onChange,
  orderedCountryCallingCodesList,
}) => {
  const value = FF.getValue(field);
  const { id } = FF.getMeta(field);

  return (
    <DhlDropdown
      changeEvent={onChange}
      dataAriaLabel={label}
      data={orderedCountryCallingCodesList}
      dataTestid={id}
      dataId={id}
      name={id}
      value={value}
    />
  );
};

CountryCallingCodeField.propTypes = {
  field: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  orderedCountryCallingCodesList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default CountryCallingCodeField;
