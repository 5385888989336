import PropTypes from "prop-types";
import { DhlCheckbox, DhlText } from "@dhl-official/react-library";
import { FormattedMessage } from "react-intl";
import React from "react";
import styled from "styled-components";

// #region Component Styles
const Container = styled.div.attrs({
  "data-testid": "parcel-urgency-options",
})`
  margin: var(--dui-size-space-10x) 0;
`;

const Info = styled(DhlText).attrs({ isParagraph: true, size: "sm" })`
  & p {
    margin-bottom: var(--dui-size-space-7x);
  }
`;

const Item = styled.div`
  margin-bottom: var(--dui-size-space-7x);
  overflow: hidden;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Option = styled(DhlCheckbox).attrs({
  size: "md",
})`
  & > div > label.md {
    height: auto;
  }
`;

const OptionContent = styled.div``;

const Title = styled(DhlText).attrs({
  isParagraph: true,
  weight: 700,
})`
  & p {
    margin: 0;
  }
`;

const SubTitle = styled(Title).attrs({ size: "sm", weight: 400 })`
  & p {
    color: var(--dui-color-gray-600);
    margin: 0;
  }
`;
// #endregion

const ParcelOptions = ({ className, onChange, selectedOptions }) => (
  <Container className={className}>
    <Info>
      <FormattedMessage id="FS2.CardShipmentProduct.parcelOptionsInfo" />
    </Info>
    <Item>
      <Option
        dataTestid="parcelOptions-Standard"
        isChecked={selectedOptions.standard}
        name="standard"
        changeEvent={() => onChange("standard")}
        value="standard"
      >
        <OptionContent>
          <Title>
            <FormattedMessage id="FS2.CardShipmentProduct.parcelOptionsStandard" />
          </Title>
          <SubTitle>
            <FormattedMessage
              id="FS2.CardShipmentProduct.parcelOptionsStandardExplanation"
              values={{
                br: <br />,
              }}
            />
          </SubTitle>
        </OptionContent>
      </Option>
    </Item>
    <Item>
      <Option
        dataTestid="parcelOptions-Expedited"
        isChecked={selectedOptions.expedited}
        name="expedited"
        changeEvent={() => onChange("expedited")}
        value="expedited"
      >
        <OptionContent>
          <Title>
            <FormattedMessage id="FS2.CardShipmentProduct.parcelOptionsExpedited" />
          </Title>
          <SubTitle>
            <FormattedMessage
              id="FS2.CardShipmentProduct.parcelOptionsExpeditedExplanation"
              values={{
                br: <br />,
              }}
            />
          </SubTitle>
        </OptionContent>
      </Option>
    </Item>
  </Container>
);

ParcelOptions.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selectedOptions: PropTypes.object,
};

export default ParcelOptions;
